import Link from "next/link";
import { cn } from "@costar-ui/cosmos";
import {
  ClientTrackingEvent,
  ClientTrackingArea,
  trackingDetails,
} from "../../../helpers/event-tracking-helpers";

export interface INavigationItem {
  active?: boolean;
  name?: string;
  url?: string;
  nestedItems?: INavigationItem[];
  target?: string;
}

export interface INavigation {
  items: INavigationItem[];
  className?: string;
  sideElement?: JSX.Element;
  disableClicks?: boolean; // TEMP MIGHT NEED TO ALLOW CLICKS DISCUSS WITH KHALON
  isSubNav?: boolean;
}

const getPathNameFromUrl = (url?: string) =>
  url && url.includes("?") ? url.split("?")[0] : url;

export const setActiveNavigation = (
  navigationItems: INavigationItem[],
  pathName: string
) => {
  const navigationItemsWithActiveState = navigationItems.map((item) => {
    const navItem = { ...item };

    const itemPath = getPathNameFromUrl(item.url);

    if (
      itemPath &&
      (pathName.includes(itemPath + "/") || pathName === itemPath) &&
      pathName !== "/" &&
      itemPath !== "/"
    ) {
      navItem.active = true;
    } else {
      navItem.active = false;
    }
    if (navItem.nestedItems) {
      const subItems = navItem.nestedItems;
      for (const subItem of subItems) {
        const subItemPath = getPathNameFromUrl(subItem.url);
        if (
          subItemPath &&
          (pathName.includes(subItemPath + "/") || pathName === subItemPath)
        ) {
          subItem.active = true;
          navItem.active = true;
        } else {
          subItem.active = false;
        }
      }
    }

    return navItem;
  });

  if (
    pathName === "/" ||
    //check for path includes "/actualities" NOT at the nested level
    (pathName.includes("/actualites") && !pathName.indexOf("/actualites")) ||
    (pathName.includes("/thematiques") && !pathName.includes("/transactions"))
  ) {
    navigationItemsWithActiveState[0].active = true;
  }

  return navigationItemsWithActiveState;
};

export const Navigation = (props: INavigation) => {
  const { items, className, isSubNav, disableClicks } = props;
  return (
    <nav className={className}>
      <div className="flex h-[32px] justify-between px-4 lg:px-8">
        <ul
          className={cn("flex gap-6", {
            "min-[1145px]:gap-6 gap-4": !!isSubNav,
          })}
          automation-id="header-navigation-items"
        >
          {items.map((item) => {
            return (
              <li
                className="flex items-center"
                key={item.name}
                automation-id={`header-navigation-item-${item.name
                  ?.toLowerCase()
                  ?.replace(/ /g, "")}`}
              >
                <Link
                  className={cn(
                    "flex items-center overflow-hidden whitespace-nowrap hover:opacity-50",
                    {
                      "mt-1 h-7 border-b-4 border-[#ec4a08] font-bold":
                        item.active,
                      "h-full": !item.active,
                    },
                    {
                      // "cursor-not-allowed": disableClicks, //need another style to show disabled @DCATZVA
                      "pointer-events-none": disableClicks,
                    }
                  )}
                  href={item.url || "/"}
                  target={item.target}
                  data-track-click
                  data-track-details={trackingDetails({
                    event: ClientTrackingEvent.NAVIGATION_CLICK,
                    area: ClientTrackingArea.HEADER,
                  })}
                  prefetch={false}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="items-center justify-center">{props.sideElement}</div>
      </div>
    </nav>
  );
};
