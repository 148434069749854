import { SideMenuContent } from "../menu/SideMenuContent";

import { Avatar } from "../Avatar";
import { AvatarAndName } from "../AvatarAndName";

type Pagelink = {
  name: string;
  url: string;
};
export interface ISubscriberMenuContents {
  userInitials: string;
  userFullName: string;
  messages: {
    groupName: string;
  };
  pageLinks: {
    motDePasse: Pagelink;
    deconnexion: Pagelink;
    accountManager?: Pagelink;
  };
}

export const SubscriberMenuContents = (props: ISubscriberMenuContents) => {
  const { userFullName, userInitials, messages, pageLinks } = props;
  const innerLinks = [pageLinks.motDePasse];
  if (pageLinks.accountManager) {
    innerLinks.push(pageLinks.accountManager);
  }
  return (
    <SideMenuContent
      items={[
        {
          items: [
            {
              element: (
                <div className="m-4 bg-[#f5f5f5] p-4">
                  <AvatarAndName>
                    <Avatar text={userInitials} />
                    <span
                      automation-id="side-menu-user-profile-name"
                      className="rounded-sm py-1"
                    >
                      {userFullName}
                    </span>
                  </AvatarAndName>
                </div>
              ),
            },
          ],
        },
        {
          groupName: messages.groupName,
          items: innerLinks,
        },
        {
          items: [
            {
              element: (
                <a
                  href={pageLinks.deconnexion.url}
                  className="w-full px-4 py-2 text-p-18 leading-6 hover:bg-[#eaeaea] hover:text-primary"
                >
                  {pageLinks.deconnexion.name}
                </a>
              ),
            },
          ],
        },
      ]}
    />
  );
};
