import { ComponentPropsWithoutRef, ReactNode } from "react";
import { cn } from "@costar-ui/cosmos";
interface ITextButton extends ComponentPropsWithoutRef<"button"> {
  children?: ReactNode;
}

export const TextButton = (props: ITextButton) => {
  return (
    <button
      {...props}
      className={cn(
        "text-input-text rounded-sm bg-transparent text-left outline-none hover:text-primary",
        props.className
      )}
    >
      {props.children}
    </button>
  );
};
