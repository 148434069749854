"use client";
import { SignInButton } from "../../SignInButton";
import { AvatarAndName } from "../AvatarAndName";
import Image from "next/image";
import { SideMenu } from "../menu/SideMenu";
import { Avatar } from "../Avatar";
import Link from "next/link";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

import {
  SubscriberMenuContents,
  ISubscriberMenuContents,
} from "./SubscriberMenuContents";
import {
  ClientTrackingArea,
  ClientTrackingEvent,
  trackingDetails,
} from "@/components/helpers/event-tracking-helpers";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";

export const UnAuthenticatedUserSection = ({
  messages,
}: {
  messages: {
    login: string;
    sabonner: string;
  };
}) => {
  return (
    <div className="flex items-center gap-3 xl:gap-6">
      <SignInButton
        signInButtonVariant={"linkVariant"}
        data-track-click
        data-track-details={trackingDetails({
          event: ClientTrackingEvent.SIGN_IN_CLICKED,
          area: ClientTrackingArea.HEADER,
        })}
      >
        <AvatarAndName>
          <Avatar className="hidden shrink-0 lg:flex" />
          <span className="shrink-0 pt-[1.5px] text-p-14 font-bold">
            {messages.login}
          </span>
        </AvatarAndName>
      </SignInButton>
      <Link href="/abonnement" className="hidden lg:block">
        <button
          className="h-[32px] shrink-0 rounded-sm bg-[#0559b3] px-2 py-1 text-p-16 text-white lg:text-p-14 xl:text-p-16"
          automation-id="header-subscribe-button"
        >
          {messages.sabonner}
        </button>
      </Link>
    </div>
  );
};

type User = {
  userFullName?: string;
  userInitials?: string;
  subjectId?: string;
};

type UserSectionProps = Pick<ISubscriberMenuContents, "pageLinks"> & {
  messages: {
    groupName: string;
    login: string;
    sabonner: string;
  };
};

const getUserInfo = async () => {
  const userInfoResponse = await fetch("/api/user-info", {
    method: "POST",
  });
  if (userInfoResponse.ok) {
    const userInfo = (await userInfoResponse.json()) as User;

    if (userInfo.userFullName && userInfo.userInitials) {
      return userInfo;
    }
  }
};

const isAccountManager = async () => {
  const managerResponse = await fetch("/api/is-account-manager");
  if (managerResponse.ok) {
    return !!(await managerResponse.json()).isAccountManager;
  }
  return false;
};

export const UserSection = (props: UserSectionProps) => {
  const {
    messages,
    pageLinks: { motDePasse, deconnexion, accountManager },
  } = props;
  const [user, setUser] = useState<User>();
  const [accountManagerStatus, setAccountManagerStatus] = useState<string>();
  const searchParams = useSearchParams();
  const searchParamsReason = searchParams?.get("r");

  const { userFullName, userInitials } = user || {};
  const pageLinks = {
    motDePasse,
    deconnexion,
    ...(accountManagerStatus === "success" && { accountManager }),
  };

  useEffect(() => {
    (async function () {
      const userInfo = await getUserInfo();
      if (userInfo) {
        setUser(userInfo);
        if (userInfo.subjectId) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "login",
            userId: userInfo.subjectId,
          });
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (!!searchParamsReason) {
        const userInfo = await getUserInfo();
        if (!userInfo) {
          setUser(undefined);
        }
      }
    })();
  }, [searchParamsReason]);

  const checkAccountManager = useCallback(() => {
    if (!accountManagerStatus) {
      setAccountManagerStatus("pending");
      (async function () {
        const isManager = await isAccountManager();
        if (isManager) {
          setAccountManagerStatus("success");
        } else {
          setAccountManagerStatus("failure");
        }
      })();
    }
  }, [accountManagerStatus, setAccountManagerStatus]);

  return (
    <section
      automation-id="header-user-profile-container"
      className="flex shrink items-center justify-end lg:min-w-[215px] xl:min-w-[240px] max-lg:grow max-lg:basis-0"
    >
      {userFullName && userInitials ? (
        <SideMenu
          menuContentElement={
            <SubscriberMenuContents
              userFullName={userFullName}
              userInitials={userInitials}
              messages={messages}
              pageLinks={pageLinks}
            />
          }
          toggleButtonElement={
            <div className="flex items-center" automation-id="user-menu-toggle">
              <AvatarAndName>
                <Avatar text={userInitials} />
                <span
                  automation-id="header-user-profile-name"
                  className="hidden rounded-sm pt-[1.5px] text-p-14 font-bold lg:block"
                >
                  {userFullName}
                </span>
              </AvatarAndName>
              <Image
                src="/menu-chevron.svg"
                alt="Dropdown arrow icon"
                width={20}
                height={20}
                priority
                className="hidden lg:block"
              />
            </div>
          }
          side={"right"}
          onSlide={checkAccountManager}
        />
      ) : (
        <UnAuthenticatedUserSection messages={messages} />
      )}
    </section>
  );
};

export const UserSectionFallback = (props: UserSectionProps) => {
  const { messages } = props;

  return (
    <section
      automation-id="header-user-profile-container"
      className="flex shrink items-center justify-end lg:min-w-[215px] xl:min-w-[240px] max-lg:grow max-lg:basis-0"
    >
      <UnAuthenticatedUserSection messages={messages} />
    </section>
  );
};
