import Link, { LinkProps } from "next/link";
import { PropsWithChildren } from "react";
import {
  ClientTrackingEvent,
  ClientTrackingArea,
  trackingDetails,
} from "../../../helpers/event-tracking-helpers";
export interface IMenuLink extends LinkProps, PropsWithChildren {
  target?: string;
}

export const MenuLink = (props: IMenuLink) => {
  return (
    <Link
      className="w-full px-4 py-2 text-p-18 leading-6 hover:bg-[#eaeaea] hover:text-[#0559b3]"
      data-track-click
      data-track-details={trackingDetails({
        event: ClientTrackingEvent.NAVIGATION_CLICK,
        area: ClientTrackingArea.HAMBURGER_MENU,
      })}
      {...props}
    >
      {props.children}
    </Link>
  );
};
