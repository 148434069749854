"use client";

import { useEffect, useRef, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { cn } from "@costar-ui/cosmos";
import useClickLinkInside from "../../hooks/useClickLinkInside";

export interface ISideMenu {
  toggleButtonElement: JSX.Element;
  menuContentElement: JSX.Element;
  side?: "right" | "left";
  onSlide?: (isOpen: boolean) => void;
}

export const SideMenu = (props: ISideMenu) => {
  const [isOpen, setIsOpen] = useState(false);
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const node = useRef(null);
  const { menuContentElement, toggleButtonElement, side, onSlide } = props;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    onSlide?.(!isOpen);
  };

  useClickLinkInside(node, isOpen, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setIsOpen(false);
  }, [pathName, searchParams]);

  return (
    <div className="relative flex">
      {/* Hamburger Button */}
      <button type="button" className="relative" onClick={toggleMenu}>
        {toggleButtonElement}
      </button>

      {/* Menu */}
      <div
        ref={node}
        className={cn(
          "fixed top-0 z-50 h-screen w-[80%] max-w-[380px] overflow-y-auto overscroll-contain bg-white shadow-lg transition-transform lg:overflow-y-visible",
          {
            "left-0": side === "left" || !side,
            "right-0": side === "right",
            "translate-x-0": isOpen,
            "-translate-x-full": !isOpen && side === "left",
            "translate-x-full": !isOpen && side === "right",
          }
        )}
      >
        <div>{menuContentElement}</div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed left-0 top-0 z-30 h-screen w-screen bg-[#404040] opacity-50"
          onClick={toggleMenu}
        />
      )}
    </div>
  );
};

export const SideMenuFallback = (props: ISideMenu) => {
  const isOpen = false;

  const { menuContentElement, toggleButtonElement, side } = props;

  return (
    <div className="relative flex">
      {/* Hamburger Button */}
      <button type="button" className="relative">
        {toggleButtonElement}
      </button>

      {/* Menu */}
      <div
        className={cn(
          "bg-content fixed top-0 z-50 h-screen w-[80%] max-w-[380px] overflow-y-auto overscroll-contain shadow-lg transition-transform lg:overflow-y-visible",
          {
            "left-0": side === "left" || !side,
            "right-0": side === "right",
            "translate-x-0": isOpen,
            "-translate-x-full": !isOpen && side === "left",
            "translate-x-full": !isOpen && side === "right",
          }
        )}
      >
        <div>{menuContentElement}</div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div className="bg-gray-500 fixed left-0 top-0 z-30 h-screen w-screen" />
      )}
    </div>
  );
};
